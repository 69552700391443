import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState.modals, { type, payload = {} }) => {
  switch (type) {
    case ActionTypes.TOGGLE_INVEST_MODAL:
      return { ...state, investModalIsOpen: !state.investModalIsOpen };
    case ActionTypes.TOGGLE_INVEST_PRIVATE_BANKERS_MODAL:
      return {
        ...state,
        investPrivateBankersModalIsOpen: !state.investPrivateBankersModalIsOpen
      };
    case ActionTypes.TOGGLE_ISSUER_CONTACT_MODAL:
      return {
        ...state,
        issuerContactModalIsOpen: !state.issuerContactModalIsOpen
      };
    case ActionTypes.TOGGLE_INVEST_CODE_MODAL:
      return {
        ...state,
        isInvestCodeModalOpened: !state.isInvestCodeModalOpened
      };
    case ActionTypes.TOGGLE_INVEST_WITH_CODE_CONFIRM_MODAL:
      return {
        ...state,
        isInvestWithCodeConfirmModalOpened: !state.isInvestWithCodeConfirmModalOpened
      };
    case ActionTypes.TOGGLE_PRIVATE_BANKER_MODAL:
      return {
        ...state,
        privateBankerModalIsOpen: !state.privateBankerModalIsOpen
      };
    case ActionTypes.TOGGLE_REPAY_MODAL:
      return { ...state, repayModalIsOpen: !state.repayModalIsOpen };
    case ActionTypes.TOGGLE_SP_REQUIRED_INFO_MODAL:
      return { ...state, spModalIsOpen: !state.spModalIsOpen };
    case ActionTypes.MODAL_REJECT_PROJECT_TOGGLE:
      return {
        ...state,
        rejectProject: !state.rejectProject
      };
    case ActionTypes.TOGGLE_FIRST_TIME_VIEW_MODAL:
      return {
        ...state,
        isFirstTimeViewModal: {
          isOpen: !state.isFirstTimeViewModal.isOpen,
          projectId: payload.projectId
        }
      };
    case ActionTypes.TOGGLE_ACCESS_DATAROOM_MODAL:
      return {
        ...state,
        isAccessDataroomModalOpened: !state.isAccessDataroomModalOpened
      };
    case ActionTypes.MODAL_TOGGLE:
      return {
        ...state,
        spModalToggle: {
          isOpen: !state.spModalToggle.isOpen,
          selectedItemId: payload
        }
      };
    case ActionTypes.MODAL_APPROVE_TOGGLE:
      return {
        ...state,
        approveModalToggle: {
          approveModalIsOpen: !state.approveModalToggle.approveModalIsOpen,
          approveSelectedItemId: payload
        }
      };

    default:
      return state;
  }
};
