import React, { Component } from 'react';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import Modal from 'shared/components/Modal';
import IsUser from 'shared/utils/user/IsUser';
import { ThemedButton } from 'shared/styled/components';

class SignNDAModal extends Component {
  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const {
      close, nda, userType, t, tReady, title = null
    } = this.props;
    const isUser = new IsUser(userType);
    const translationContext = isUser.allSP ? { context: 'sp' } : {};

    return nda && nda.url ? (
      <Modal
          config={{
            withoutFooter: false,
            withoutHeader: false,
            overlayClassName: 'react-modal-overlay_full-screen',
            title: t('title', translationContext),
            isOpen: true,
            onRequestClose: () => {
              this.props.close();
            }
          }}
          shouldCloseOnOverlayClick={false}
          width = '100%'
          title={`${title
          || t('title', translationContext)}. Please wait few seconds until document is opened.`}
          footer={
          <React.Fragment>
            <ThemedButton
                onClick={() => {
                  close();
                }}
            >
              Close
            </ThemedButton>
          </React.Fragment>
        }
      >
        <iframe
            title={t('title', translationContext)}
            type="text/html"
            frameBorder="0"
            src={nda.url}
            name="iframe_a"
            width="100%"
        >
          {'Your browser does not support iframes.'}
        </iframe>
      </Modal>
    ) : null;
  }
}

SignNDAModal.propTypes = {};
SignNDAModal.defaultProps = {};

export default compose(withNamespaces('web/components/SignNDAModal'))(SignNDAModal);
