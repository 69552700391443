import { ActionTypes } from '../constants';
import initialState from '../store/initialState';

export default (state = initialState.confirmPassword, action) => {
  switch (action.type) {
    case ActionTypes.CONFIRM_EMAIL_REQUEST_FAILURE:
    case ActionTypes.CONFIRM_EMAIL_REQUEST_SUCCESS:
      return { ...state, userType: action.payload.userType };
    default:
      return state;
  }
};
