import { ActionTypes } from '../constants';
import { ActionTypes as sharedActionTypes } from 'shared/constants';

export function changePassword(values) {
  return {
    type: ActionTypes.CHANGE_PASSWORD,
    values
  };
}

export function spUpdateLogo(file) {
  return {
    type: ActionTypes.SP_UPDATE_LOGO,
    file
  };
}

export function signNDA(url, id) {
  return {
    type: ActionTypes.SIGN_NDA,
    url,
    id
  };
}

export function isSignNDA(url, id) {
  return {
    type: ActionTypes.IS_SIGN_NDA,
    url,
    id
  };
}

export function getKYCUserStatus(id, projectionName = false) {
  return {
    type: ActionTypes.KYC_USER_STATUS,
    id,
    projectionName
  };
}

export const createCV = data => ({
  type: ActionTypes.USER_CV_CREATE_REQUEST,
  requestConfig: {
    data
  },
  onSuccessActions: [
    {
      type: ActionTypes.USER_CV_GET_REQUEST
    },
    {
      type: sharedActionTypes.USER_INFORMATION_REQUEST
    }
  ]
});

export const updateCV = (data, id) => ({
  type: ActionTypes.USER_CV_UPDATE_REQUEST,
  requestConfig: {
    data,
    id
  },
  onSuccessActions: [
    {
      type: ActionTypes.USER_CV_GET_REQUEST
    },
    {
      type: sharedActionTypes.USER_INFORMATION_REQUEST
    }
  ]
});

export const uploadCVFileAction = file => ({
  type: ActionTypes.ADVISOR_UPLOAD_CV_FILE_REQUEST,
  payload: {
    file
  }
});

export const getCV = () => ({
  type: ActionTypes.USER_CV_GET_REQUEST
});

export const setUserKYCStatusAction = (requestConfig, onSuccess) => ({
  type: ActionTypes.SET_USER_KYC_STATUS,
  requestConfig,
  onSuccess
});

export const approveModalToggle = (id = null) => ({
  type: ActionTypes.MODAL_APPROVE_TOGGLE,
  payload: id
});

export const screenUserAction = (id = null) => ({
  type: ActionTypes.SCREEN_USER,
  id
});

export const modalToggle = (id = null) => ({
  type: ActionTypes.MODAL_TOGGLE,
  payload: id
});

export const asideNavToggleAction = () => ({
  type: ActionTypes.ASIDE_NAV_TOGGLE
});

export const asideNavSetStatusAction = status => ({
  type: ActionTypes.ASIDE_NAV_SET_STATUS,
  status
});

export const setUserStepperStatusAction = status => ({
  type: ActionTypes.SET_USER_STEPPER_STATUS,
  requestConfig: {
    data: {
      showStepper: status
    }
  },
  onSuccessActions: [
    {
      type: sharedActionTypes.USER_INFORMATION_REQUEST
    }
  ]
});

export const getKYCDocumentsStatusAction = params => ({
  type: ActionTypes.KYC_DOCUMENTS_STATUS,
  payload: {}
});

export const KYCDocumentsSaveAction = (files, name) => ({
  type: ActionTypes.SAVE_KYC_DOCUMENTS,
  payload: {
    files,
    name
  }
});

export const getNDATemplateAction = (url, id) => ({
  type: ActionTypes.GET_NDA_TEMPLATE,
  payload: {
    url,
    id
  }
});

export const getPrivateBankersListAction = () => ({
  type: ActionTypes.GET_PRIVATE_BANKERS_LIST
});

export const deletePrivateBanker = email => ({
  type: ActionTypes.DELETE_PRIVATE_BANKER,
  requestConfig: {
    email
  }
});

export const getCoApprovalIsRequired = () => ({
  type: ActionTypes.GET_IF_CO_APPROVAL_IS_REQUIRED,

});

export const setCurrentPageTitle = (currentPage) => {
  return {
    type: ActionTypes.SET_CURRENT_PAGE,
    payload: currentPage
  };
};