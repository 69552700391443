import ActiveRecord from './ActiveRecord';
import NetworkLayer from '../utils/networkLayer';
import utils from '../utils';

import sharedUrlsConfig from 'shared/utils/config';
import {projectsApi} from "shared/routes/urlLocations";
import {setProjectPocs} from "../actions/strategicPartnersActions";

class Project extends ActiveRecord {
  constructor() {
    super();
  }

  issuerCreationProject = (url, data) => {
    const request = {
      data
    };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  issuerSaveProjectLinks = (url, data) => {
    // TODO: rempove next 2 lines
    delete data.createdAt;
    delete data.lastModifiedAt;
    const request = {
      data
    };
    return NetworkLayer.putJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  issuerProjectPublish = (url, data) => {
    // TODO: rempove next 2 lines
    delete data.createdAt;
    delete data.lastModifiedAt;
    const request = {
      data
    };
    return NetworkLayer.putJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  issuerProjectEditPublish = (url, data) => {
    const request = {
      data
    };
    return NetworkLayer.putJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  getProjectDetails = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  issuerProjectEdit = (url, data) => {
    // TODO: rempove next 2 lines
    delete data.createdAt;
    delete data.lastModifiedAt;
    const request = {
      data
    };
    return NetworkLayer.putJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  setProjectPocs = (url, {projectId, externalPocs, internalPocs}) => {
    const request = {
      externalPocs: externalPocs,
      internalPocs: internalPocs
    };
    return NetworkLayer.putJson(`${url}/${projectId}/set-project-pocs`, {data : request})
      .then(response => response)
      .catch(e => e);
  };

  fileCategories = url => NetworkLayer.getJson(url)
    .then(response => response)
    .catch(e => e);

  issuerProjectEditFiles = (url, data) => {
    const request = {
      data
    };
    return NetworkLayer.putJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  issuerProjectDeletFile = url => {
    return NetworkLayer.remove(url)
      .then(response => response)
      .catch(e => e);
  };

  removeProject = url => {
    return NetworkLayer.removeJson(url)
      .then(response => response)
      .catch(e => e);
  };

  closeProject = (url, data) => {
    const request = {
      data
    };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  getAllProjectData = (url, { id, projection }) => {
    return NetworkLayer.getJson(`${url}/${id}`, {
      params: {
        projection
      }
    })
      .then(response => response)
      .catch(e => e);
  };

  getTargetData = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  getProjectRejectionsHistory = (url, { id, projection }) => {
    return NetworkLayer.getJson(`${url}/${id}`, {
      params: {
        projection
      }
    })
      .then(response => response)
      .catch(e => e);
  };

  getProjectsSearchResult = (url, { filters }) => {
    return NetworkLayer.getJson(url, {
      data: filters
    })
      .then(response => response)
      .catch(e => e);
  };

  getUsersProjects = (url, params) => {
    return NetworkLayer.getJson(`${url}/${params.projectsType}`, {
      params
    })
      .then(response => response)
      .catch(e => e);
  };

  toggleFeaturedStatus(url, { id }) {
    return NetworkLayer.putJson(`${url}/${id}/featured/toggle`)
      .then(response => response)
      .catch(e => e);
  }

  getSpProjects = (urlMain, {
    projection, page, sort, url
  }) => {
    return NetworkLayer.getJson(`${utils.config.apiProjectUrl}/projects/${url}`, {
      params: {
        page,
        sort,
        projection
      }
    })
      .then(response => response)
      .catch(e => e);
  };

  getProjectsStatistic = url => {
    return this.fetchList(url);
  };

  getAllTags = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  fetchCurrencies = () => {
    return NetworkLayer.getJson(`${sharedUrlsConfig.apiProjectUrl}/currencies`)
      .then(response => response)
      .catch(e => e);
  };

  approveProject(url, { id }) {
    return NetworkLayer.putJson(`${url}/${id}/approve`)
      .then(response => response)
      .catch(e => e);
  }

  rejectProject(url, { id, data }) {
    const request = {
      data
    };
    return NetworkLayer.putJson(`${url}/${id}/reject`, request)
      .then(response => response)
      .catch(e => e);
  }

  getDataRoomRequestList = (url, {
    projectId, listType, page, sort, size
  }) => {
    return NetworkLayer.getJson(
      `${utils.config.apiProjectsUrl}/${projectId}/data-room-${listType}-requests`,
      {
        params: {
          page,
          sort,
          size
        }
      }
    )
      .then(data => ({ ...data, response: { ...data.response, listType } }))
      .catch(e => e);
  };

  approveDataRoomReqeust = (url, { projectId, userId, listType }) => {
    return NetworkLayer.postJson(
      `${utils.config.apiProjectsUrl}/${projectId}/data-room-${listType}-requests/${userId}/approve`
    )
      .then(response => response)
      .catch(e => e);
  };

  rejectDataRoomRequest = (url, {
    projectId, userId, listType, reason
  }) => {
    return NetworkLayer.postJson(
      `${utils.config.apiProjectsUrl}/${projectId}/data-room-${listType}-requests/${userId}/reject`,
      {
        data: {
          reason
        }
      }
    )
      .then(response => response)
      .catch(e => e);
  };

  getInvestmentsList = (url, {
    projectId, page, sort, size
  }) => {
    return NetworkLayer.getJson(`${utils.config.apiProjectsUrl}/${projectId}/investments`, {
      params: {
        page,
        sort,
        size
      }
    })
      .then(response => response)
      .catch(e => e);
  };

  acceptWarningAndDisclaimer = (url, { projectId }) => {
    return NetworkLayer.postJson(
      `${utils.config.apiProjectsUrl}/${projectId}/warning-and-disclaimer-documents/accept`
    )
      .then(response => response)
      .catch(e => e);
  };

  isFirstTimeView = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  getIsProjectFinished = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  sendDataroomAccessRequest = (url, { projectId, data }) => {
    return NetworkLayer.postJson(
      `${utils.config.apiProjectsUrl}/${projectId}/data-room-access-requests`,
      {
        data
      }
    )
      .then(response => response)
      .catch(e => e);
  };

  sendIssuerContactRequest = (url, { projectId, data }) => {
    return NetworkLayer.postJson(
      `${utils.config.apiProjectUrl}/projects/${projectId}/contact-creator`,
      {
        data
      }
    )
      .then(response => response)
      .catch(e => e);
  };

  updateLike(url) {
    return NetworkLayer.putJson(`${url}`)
      .then(response => response)
      .catch(e => e);
  }

  updateFavorite(url) {
    return NetworkLayer.putJson(`${url}`)
      .then(response => response)
      .catch(e => e);
  }
}

export default Project;
