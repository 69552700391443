import { connect } from 'react-redux';
import { compose } from 'redux';
import form from './MainLayout';
import { withRouter } from 'react-router';
import { signOut } from '../../actions/authActions';
import refreshToken from '../../actions/refreshTokenActions';
import { isSignNDA, signNDA } from '../../actions/userDataActions';
import withCheckUserActivity from '../../hoc/checkUserActivity';
import {
  pushNotificationsConnectAction,
  pushNotificationsDisconnectAction,
  getUnreadNotificationsCountAction
} from '../../actions/pushNotificationsActions';
import {getSpPlatformConfig} from 'shared/actions/spPlatformConfigActions'

function mapDispatchToProps(dispatch) {
  return {
    pushNotificationsConnect: () => dispatch(pushNotificationsConnectAction()),
    pushNotificationsDisconnect: () => dispatch(pushNotificationsDisconnectAction()),
    signOut: () => dispatch(signOut()),
    refreshToken: () => dispatch(refreshToken()),
    getSpPlatformConfig: (subDomain) => dispatch(getSpPlatformConfig(subDomain)),
    getUnreadNotificationsCount: () => dispatch(getUnreadNotificationsCountAction()),
    signNDA: () => dispatch(signNDA()),
    isSignNDA: () => dispatch(isSignNDA())
  };
}

function mapStateToProps({
  showSpinner,
  spPlatform: { config: spPlatformConfig },
  user: {
    isFetching,
    info: {
      userType,
      subDomain,
      permissions,
      givenName,
      type,
      familyName,
      spLogo,
      theme,
      status,
      ndaSigned,
      cvCreated,
      showStepper,
      phone,
      email
    },
    info,
    strategicPartners: { exists },
    currentPage,
  },

  notifications: {
    unread: { count }
  },
  NDA,
  prompt
}) {
  return {
    showSpinner,
    userType,
    subDomain,
    permissions,
    familyName,
    givenName,
    phone,
    email,
    type,
    spLogo,
    theme,
    unread: count,
    exists,
    status,
    ndaSigned,
    NDA,
    cvCreated,
    prompt,
    showStepper,
    isUserFetching: isFetching,
    info,
    currentPage,
    spPlatformConfig
  };
}

export default compose(
  withCheckUserActivity,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(form);
