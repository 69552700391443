import { ActionTypes } from '../constants';
import userTypes from 'shared/constants/userTypes';

export const fetchListSPAdmins = (
  page,
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_ADMINS_LIST_REQUEST,
  requestConfig: {
    projection: 'admin',
    sort: `${sort.field},${sort.order}`,
    size: 20,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const spAdminCreate = values => ({
  type: ActionTypes.SP_ADMIN_CREATE,
  requestConfig: {
    ...values,
    type: 'USER',
    userType: userTypes.ADMIN_STRATEGIC_PARTNER,
  }
});

export const spAdminActivation = (id, enable, page, sort) => ({
  type: ActionTypes.SP_ADMIN_ACTIVATION_REQUEST,
  requestConfig: {
    id,
    enable
  },
  onSuccessActions: fetchListSPAdmins({current: page.number + 1}, null, sort)
});

export const getSPCompanyKYC = () => ({
  type: ActionTypes.SP_ADMIN_KYC_REQUEST
});
