import React, { Component } from 'react';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import WalletIcon from 'shared/components/Icons/WalletIcon';
import ClockIcon from '../Icons/ClockIcon';
import ExclamationIcon from '../Icons/ExclamationIcon'
import PCIcon from '../Icons/PCIcon';
import userStatus from 'shared/constants/userStatus';
import Animation from 'shared/components/Animation';
import withScrollbars from 'shared/hoc/withScrollbars';
import styled from 'styled-components';
import { ThemedAntIcon, ThemedButton, ThemedText } from 'shared/styled/components';
import { Tag } from 'antd';
import { isSp } from 'shared/utils/isSp';
import { transparentize } from 'polished';

const ThemedChip = styled(Tag)`
background-color: ${props => {return props.success ? props.theme.greenSuccess : props.theme.platformPrimary}} !important;
color: ${props => {return isSp() ? props.theme.gray.sp50 : props.theme.grayLight}};
padding: 8px;
font-size: 11px;
border: none;
font-weight: normal;
text-transform: none;
margin-left: 8px;
`

const ThemedGuides = styled.div`
  .guide__description {
    color: ${isSp() ? "black" : "white"};
  }
    .guide__step-description{
      margin-top: 5px;
    }
  .guide__step-title{
    color: ${isSp() ? "#666666" : "white"} !important;
  }
  .guide__step-number {
  ${props => { 
    return isSp()
    ? 
    `flex: 0;
     background: ${props.theme.platformPrimary} !important;
     border-color: #CDCDCD;
     color: #fff !important;`
    :
    `flex: 0;
     background: ${props.theme.navy};
     border-color: ${props.theme.navy};`
      }
    }
  }
}
  .guide__step.pending{
  .guide__step-title{
    color: ${props => props.theme.platformPrimary} !important;
  }
  }
  .guide__step.active {
  .guide__step-title{
    color: ${props => props.theme.platformPrimary} !important;
  }
    .guide__step-number {
      ${({ theme}) => 
        isSp()
        ? `background: ${props => props.theme.platformPrimary} !important;
           border-color: #CDCDCD`
        : `background: ${props => props.theme.navy};
           border-color: ${props => props.theme.navy};`
      }
    }
  }
  .guide__step.done {
    .guide__step-number {
    color:
      ${props => transparentize(0.2, '#fff')};

    background-color: ${props => transparentize(0.4, props.theme.platformPrimary)} !important;
      }
    }
  .guide__step.disable {
    .guide__step-number {
    color:
      ${props => transparentize(0.2, '#fff')};
      background-color: ${props => transparentize(0.2, props.theme.platformPrimary)} !important;
      }
    }
`;

class Guide extends Component {
  state = {
    currentStep: {
      step: 0,
      status: 'active'
    }
  };

  componentDidMount() {
    const { status, renderStepStatus } = this.props;
    this.setState(() => ({
      currentStep: renderStepStatus(status)
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { status } = nextProps;
    const { asideNavSetStatus, renderStepStatus } = this.props;
    if (status === userStatus.WALLET_FILLED && status !== this.props.status) {
      asideNavSetStatus(true);
    }
    this.setState(() => ({
      currentStep: renderStepStatus(status)
    }));
  }

  scrollTo = element => {
    const {
      scrollbar: { scrollTo }
    } = this.props;
    scrollTo(element || 'top');
  };

  renderIcon(type) {
    return <div style={{ "margin-top": 10 }}>
      <ExclamationIcon />
    </div>;
  }

  renderStepChip(status) {
    switch (status) {
      case 'done':
        return <ThemedChip success={true}>Done</ThemedChip>
      case 'pending':
        return <ThemedChip>Pending</ThemedChip>
      default:
        return null;
    }
  }

  renderStepClass(step) {
    const { currentStep } = this.state;
    let stepState = '';

    if (step === currentStep.step) {
      stepState = currentStep.status;
    } else {
      stepState = step > currentStep.step ? 'disable' : 'done';
    }

    return stepState;
  }

  renderStepIcon(status) {
    switch (status) {
      case 'done':
        return <ThemedAntIcon type="check" />;
      case 'pending':
        return <ClockIcon />;
      default:
        return null;
    }
  }

  render() {
    const {
      t, tReady, setUserStepperStatus, content, successStep, isUser
    } = this.props;
    const { currentStep } = this.state;
    const translationContext = isUser.allSP ? { context: 'sp' } : {};

    return (
      <ThemedGuides className={isSp() ? 'guide-sp guide' : 'guide'}>
        <div className="guide__header">
          <div className="guide__icon">{this.renderIcon(content.icon)}</div>
          <div className="guide__description">{content.header}</div>
        </div>
        <div className="guide__body">
          {currentStep.step === successStep ? (
            <div className="guide__success">
              <div className="guide__success-sub-title">
                <ThemedText>{content.successSubTitle}</ThemedText>
              </div>
              <div className="guide__success-text">{content.successText(setUserStepperStatus)}</div>
              <div className="guide__success-button">
                <ThemedButton onClick={() => setUserStepperStatus()}
                  type="primary">
                  OK
                </ThemedButton>
              </div>
            </div>
          ) : content.steps ? (
            <div className="guide__steps">
              {content.steps.map((item, index) => (
                <div
                  className={`guide__step ${this.renderStepClass(index + 1)} `}
                  key={item.title + index}
                >
                  <div className="guide__step-wrap">
                    {this.renderStepClass(index + 1) == 'disable' ? (
                      <div className="guide__step-number">
                        {index + 1}
                      </div>
                    ) : (
                      <Link onClick={() => this.scrollTo(item.scrollTo)}
                        to={item.link}>
                        <div className="guide__step-number">
                          {index + 1}
                        </div>
                      </Link>
                    )}




                    <div className="guide__step-info">
                      {this.renderStepClass(index + 1) == 'disable' ? (
                        <React.Fragment>
                          <div className={`guide__step-title`}>
                            {t(item.title, translationContext)}{' '}
                          </div>
                          {this.renderStepChip(this.renderStepClass(index + 1))}
                        </React.Fragment>
                      ) : (

                        <Link
                          className={`guide__step-title${this.renderStepClass(index + 1) !== 'done' ? " active-link" : ""}`}
                          onClick={() => this.scrollTo(item.scrollTo)}
                          to={item.link}
                        >
                          {t(item.title, translationContext)}{' '}
                          {this.renderStepChip(this.renderStepClass(index + 1))}
                        </Link>
                      )}

                      
                    </div>
                   
                  </div>
                  <div className="guide__step-description">
                        {this.renderStepClass(index + 1) === 'pending' && item.pendingDescription
                          ? t(item.pendingDescription, translationContext)
                          : t(item.description, translationContext)}
                      </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </ThemedGuides>
    );
  }
}

export default compose(
  withScrollbars,
  withNamespaces('guides')
)(Guide);
