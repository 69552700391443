import React, { Component } from 'react';
import { Icon } from 'antd';
import Guide from '../Guides';
import IsUser from 'shared/utils/user/IsUser';
import {
  RenderSPInvestorGuideContent,
  renderSPInvestorStepStatus
} from '../Guides/SPInvestorGuide';
import { RenderIssuerGuideContent, renderIssuerStepStatus } from '../Guides/IssuerGuide';
import { RenderInvestorGuideContent, renderInvestorStepStatus } from '../Guides/InvestorGuide';
import { RenderSPAdminGuideContent, renderSPAdminStepStatus } from '../Guides/SPAdminGuide';
import { RenderAdvisorGuideContent, renderAdvisorStepStatus } from '../Guides/AdvisorGuide/index';
import styled from 'styled-components';

const ThemedAsideNav = styled.div`
  .aside-nav__button {
    background: ${props => props.theme.platformPrimary};
  }
`;

class AsideNav extends Component {
  render() {
    const {
      asideNav: { isOpen },
      asideNavToggle,
      status,
      userType,
      setUserStepperStatus,
      asideNavSetStatus
    } = this.props;
    const isUser = new IsUser(userType);
    return (
      <ThemedAsideNav className={`stepper-container`}>
        <div className="aside-nav__wrap">
          <div className="aside-nav__content">
            {isUser.investor && (
              <Guide
                  asideNavSetStatus={status => asideNavSetStatus(status)}
                  setUserStepperStatus={setUserStepperStatus}
                  status={status}
                  content={RenderInvestorGuideContent}
                  renderStepStatus={renderInvestorStepStatus}
                  successStep={3}
                  isUser={isUser}
              />
            )}
            {isUser.issuer && (
              <Guide
                  asideNavSetStatus={status => asideNavSetStatus(status)}
                  setUserStepperStatus={setUserStepperStatus}
                  status={status}
                  content={RenderIssuerGuideContent}
                  renderStepStatus={renderIssuerStepStatus}
                  successStep={3}
                  isUser={isUser}
              />
            )}
            {isUser.investorSP && (
              <Guide
                  asideNavSetStatus={status => asideNavSetStatus(status)}
                  setUserStepperStatus={setUserStepperStatus}
                  status={status}
                  content={RenderSPInvestorGuideContent}
                  renderStepStatus={renderSPInvestorStepStatus}
                  successStep={1}
                  isUser={isUser}
              />
            )}
            {isUser.adminSP && (
              <Guide
                  asideNavSetStatus={status => asideNavSetStatus(status)}
                  setUserStepperStatus={setUserStepperStatus}
                  status={status}
                  content={RenderSPAdminGuideContent}
                  renderStepStatus={renderSPAdminStepStatus}
                  successStep={4}
                  isUser={isUser}
              />
            )}
            {isUser.advisor && (
              <Guide
                  asideNavSetStatus={status => asideNavSetStatus(status)}
                  setUserStepperStatus={setUserStepperStatus}
                  status={status}
                  content={RenderAdvisorGuideContent}
                  renderStepStatus={renderAdvisorStepStatus}
                  successStep={4}
                  isUser={isUser}
              />
            )}
          </div>
        </div>
      </ThemedAsideNav>
    );
  }
}

export default AsideNav;
