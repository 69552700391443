import React from 'react';
import userStatus from 'shared/constants/userStatus';
import { userProfile, projectsAll } from 'shared/routes/urlLocations';
import { Link } from 'react-router-dom';

export const RenderAdvisorGuideContent = {
  icon: 'wallet',
  header: 'Please fill following information to be able to use the platform.',
  successSubTitle: 'Congratulations!',
  successText: action => (
    <React.Fragment>
      You have successfully completed all steps and you can now use the platform
    </React.Fragment>
  ),
  steps: [
    {
      title: 'Fill User data',
      description: 'Please fill your user data to be shared with KYC provider.',
      link: `${userProfile}#user-data`
    },
    {
      title: 'Fill CV',
      description: 'Please fill in your CV details',
      link: `${userProfile}#user-cv`
    },
    {
      title: 'signNDA',
      description: 'signNDADescription',
      pendingDescription: 'signNDAPendingDescription',
      link: `${userProfile}#account`,
      scrollTo: 'nda'
    }
    /* {
      title: 'KYC documents',
      description: 'Attach KYC documents to confirm your person.',
      link: `${userProfile}#account`,
      scrollTo: 'kyc'
    },
    {
      title: 'Add payment method',
      description: 'Add your bank card or bank account to start work with payments.',
      link: `${userProfile}#payment-methods`
    },
    {
      title: 'Fill wallet',
      description: 'Fill your wallet to minimize platform commission.',
      pendingDescription:
        'Your transaction to fill wallet was started. We will inform you, when it is successfully finished and wallet is filled.',
      link: `${userProfile}#payment-methods`
    } */
  ]
};

export const renderAdvisorStepStatus = status => {
  switch (status) {
    case userStatus.USER_DATA_FILLED:
      return { step: 2, status: 'active' };
    case userStatus.CV_ADDED:
      return { step: 3, status: 'active' };
    case userStatus.SIGNED:
      return { step: 3, status: 'pending' };
    case userStatus.KYC_PASSED:
    case userStatus.ACCOUNT_CREATED:
    case userStatus.KYC_DOCUMENT_ADDED:
    case userStatus.KYC_DOCUMENT_VALIDATED:
    case userStatus.PAYMENT_METHOD_ADDED:
    case userStatus.WAITING_FOR_WALLET_REPLENISHMENT:
    case userStatus.WALLET_FILLED:
      return { step: 4, status: 'active' };

    default:
      return {
        step: 1,
        status: 'active'
      };
  }
};
