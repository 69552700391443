import React, { Component } from 'react';
import { compose } from 'redux';
import { Menu } from 'antd';
import withRenderMenu from 'shared/hoc/renderMenu';
import sidebarMenu from '../../constants/menu';
import SignNDAModal from '../SignNDAModal';
import IsUser from 'shared/utils/user/IsUser';
import IsStatus from 'shared/utils/IsStatus';
import screenBreakpoints from 'shared/constants/screenBreakpoints';
import THEMES from 'shared/constants/themes';
import Logo from 'shared/components/Icons/NewLogo';
import LogoSp from 'shared/components/Icons/LogoSp'
import { isSp } from '../../utils/isSp'
import { withNamespaces } from 'react-i18next';
import './index.css';
import { Link } from "react-router-dom";

class Sidebar extends Component {
  state = {
    modalVisible: false
  };

  closeModalNDA = () => {
    this.setState({
      modalVisible: false
    });

    this.props.isSignNDA();
  };

  render() {
    const {
      t,
      tReady,
      userType,
      menuList,
      defaultValues,
      userTheme,
      userStatus,
      spPlatformConfig,
      signNDA,
      ndaSigned,
      ndaUrl,
      showLogo,
      spLogo
    } = this.props;
    const closeModalNDA = this.closeModalNDA.bind(this);
    const isUser = new IsUser(userType);
    const isStatus = new IsStatus(userStatus);
    const translationContext = isUser.allSP ? { context: 'sp' } : {};

    const isSpCheck = isSp();

    return (

      <React.Fragment>
        {showLogo && (
          <div className='sidebar-logo'>
            <Link to='/projects/all'>
              {isSpCheck ? (
                spLogo ? (
                  <img
                    src={spLogo}
                    alt="Logo"
                    style={{ maxWidth: "100px", height: "auto" }}
                  />
                ) : (
                  <LogoSp />
                )
              ) : (
                <Logo />
              )}
            </Link>
          </div>
        )}


        <Menu mode={window.innerWidth > screenBreakpoints.md ? 'inline' : 'vertical-right'} style={{ backgroundColor: 'transparent', border: 'none' }}
          className={isSpCheck ? 'sidebar-menu-sp' : 'sidebar-menu'}
        >
          {menuList}
        </Menu>
        {isUser.issuer && !ndaSigned && (
          <div className="nda-prompt">
            Please{' '}
            <a
              onClick={() => {
                signNDA();
                this.setState({
                  modalVisible: true
                });
              }}
            >
              {t('signNDA', translationContext)}
            </a>
            {t('signNDAAfter', translationContext)} and wait for KYC check results to be able to
            create project on FinBursa platform.
            {this.state.modalVisible ? (
              <SignNDAModal close={closeModalNDA}
                userType={userType}
                nda={ndaUrl} />
            ) : null}
          </div>
        )}
        {isUser.issuer && isStatus.SIGNED /*  || isStatus.KYC_PASSED */ && (
          <div className="nda-prompt">
            "Representations and Warranties" document is signed and your data is being processed.
            You will receive a notification once approved.
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default compose(
  withRenderMenu(sidebarMenu),
  withNamespaces('web/components/sidebar')
)(Sidebar);
