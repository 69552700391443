import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';
import Footer from 'shared/components/Footer';
import { history } from 'shared/routes/urlLocations';
import ScrollbarProvider from 'shared/providers/Scrollbar';
import { projectsAll } from 'shared/routes/urlLocations';
import Header from 'shared/components/Header';
import ModalsProvider from 'shared/providers/Modals';
import ContactUs from 'shared/components/ContactUs';
import FullScreenSpinner from 'shared/components/FullScreenSpinner';

import Styled from '../styled';

const blankHash = '#blank';

const subDomain = window.location.host.split('.')[0];

  

class UnauthorizedStubLayout extends Component {
  scrollbar = React.createRef();

  componentDidMount() {
    if (this.props.location.hash === blankHash) {
      sessionStorage.clear();
    }
    if (sessionStorage.getItem('access_token')) {
      history.push(projectsAll);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.scrollbar.current.scrollToTop();
    }
  }

  render() {
    const { children, showSpinner, spPlatformConfig } = this.props;
    const title = spPlatformConfig ? `${subDomain} portal` : 'FinBursa application';

    return (
      <React.Fragment>
        <ModalsProvider>
          <Helmet
              title={title}
              meta={[
                { property: 'og:site_name', content: title },
                {
                  name: 'google-site-verification',
                  content: 'o_YozjcR5EjlqVyeDNbzsghv7Ae_xdGsrVHZBncpRCY'
                }
              ]}
          />
          <ScrollbarProvider
              forwardRef={this.scrollbar}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
          >
            <Styled.MainLayout>
              <Header {...this.props} />
              <div style={{ flex: 'auto', minHeight: '100%' }}>{children}</div>
              <Footer spPlatformConfig={spPlatformConfig} />
              <ContactUs />
            </Styled.MainLayout>
          </ScrollbarProvider>
          <FullScreenSpinner showSpinner={showSpinner}
              simple={!!spPlatformConfig} />
        </ModalsProvider>
      </React.Fragment>
    );
  }
}

UnauthorizedStubLayout.defaultProps = {
  children: '',
  showSpinner: false
};

UnauthorizedStubLayout.propTypes = {
  children: PropTypes.node,
  showSpinner: PropTypes.bool
};

function mapStateToProps({
  showSpinner,
  spPlatform: { config: spPlatformConfig },
  user: {
    info: { userType, cvCreated }
  }
}) {
  return {
    showSpinner,
    userType,
    cvCreated,
    spPlatformConfig
  };
}

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UnauthorizedStubLayout)
);
