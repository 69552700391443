import { ActionTypes } from '../constants';
import { takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import { history, stubPage, login } from 'shared/routes/urlLocations';
import utils from '../utils';
import Api from '../api';
import { isSp } from 'shared/utils/isSp';

function* signOut() {
  try {
    const {
      user: {
        strategicPartners: { exists }
      }
    } = yield select();
    let accessToken = sessionStorage.getItem('access_token');
    yield put({ type: ActionTypes.SHOW_SPINNER });
    yield put({ type: ActionTypes.PROMPT_VISIBILITY, isVisibility: false });
    yield put({
      type: ActionTypes.PROJECTS_SEARCH_FILTERS_SET,
      payload: {
        filters: [],
        labels: [],
        requestData: {}
      }
    });
    yield call(Api.Auth.logout, `${utils.config.apiUrl}/token/invalidate`, accessToken);
    yield call(setAuthorizationToken);
    const hostParts = window.location.host.split('.');
    if (hostParts.length >= 3) { 
      const subDomain = hostParts[0];
      const mainDomain = hostParts.slice(1).join('.');
      window.location.href = `https://${subDomain}.${mainDomain}${login}`;
    } else {
      window.location.href = `https://${window.location.host}${login}`;
    }

   

    yield put({ type: ActionTypes.PROMPT_VISIBILITY, isVisibility: true });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  } catch ({ message }) {
    yield put({
      type: ActionTypes.USER_LOGOUT_REQUEST_FAILURE,
      message
    });
  } finally {
    yield call(setAuthorizationToken);
    yield put({ type: ActionTypes.USER_LOGOUT_REQUEST_FINISHED });
  }
}

export default function* watchSignOut() {
  yield takeLatest(ActionTypes.USER_LOGOUT_REQUEST, signOut);
}
