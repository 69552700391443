import ActiveRecord from './ActiveRecord';
import NetworkLayer from '../utils/networkLayer';
import 'formdata-polyfill';
import utils from '../utils';
import sharedUrlsConfig from 'shared/utils/config';
import defaultRedirect from '../utils/defaultReferer';

class User extends ActiveRecord {
  constructor() {
    super();
    this._isAuthenticated = false;
  }

  get isAuthenticated() {
    this._isAuthenticated = sessionStorage.getItem('isAuthenticated') || false;
    return this._isAuthenticated;
  }

  info = data => {
    if (Object.keys(data).length !== 0) {
      for (let arr of Object.entries(data)) {
        sessionStorage.setItem(...arr);
      }
      sessionStorage.setItem('isAuthenticated', true);
      NetworkLayer.authToken = `${data.token_type} ${data.access_token}`;
    }
  };

  referer = history => {
    const {
      location: { state }
    } = history;
    return defaultRedirect(state);
  };

  initiateUserInformation = url => {
    return NetworkLayer.getJson(url, {})
      .then(response => response)
      .catch(e => e);
  };

  saveFile = (url, file) => {
    const request = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: {
        file
      }
    };
    return NetworkLayer.postFormData(url, request)
      .then(response => response)
      .catch(e => e);
  };

  changePassword = (url, data) => {
    return NetworkLayer.postJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  spAdminCreate = (url, data) => {
    data.reCaptchaToken = sessionStorage.getItem('recaptchaToken');
    const request = {
      data
    };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  updateSelectedRMs = (url, {id, updatedRMs}) => {
    const request = {
      data: updatedRMs,
    };
  console.log(id, updatedRMs  )
    return NetworkLayer.putJson(`${url}/${id}/edit`, request)
      .then(response => response)
      .catch(e => {
        console.error("Error updating RMs:", e);
        return e;
      });
  };


  spAdminActivation = (url, { id, enable }) => {
    const request = {
      params: {
        enable: !enable
      }
    };
    return NetworkLayer.postJson(`${url}/${id}/enable`, request)
      .then(response => response)
      .catch(e => e);
  };

  getSPCompanyKYC = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  getSubscriptionsList = (url, { subDomain = null }) => {
    return NetworkLayer.getJson(`${url}${subDomain ? `?subDomain=${subDomain}` : ''}`)
      .then(response => response)
      .catch(e => e);
  };

  spWorkerCreate = (url, data) => {
    data.reCaptchaToken = sessionStorage.getItem('recaptchaToken');
    const request = {
      data
    };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  spWorkerActivation = (url, { id, enable }) => {
    const request = {
      params: {
        enable: !enable
      }
    };
    return NetworkLayer.postJson(`${url}/${id}/enable`, request)
      .then(response => response)
      .catch(e => e);
  };

  spUserActivation = (url, { id, enable }) => {
    const request = {
      params: {
        enable: !enable
      }
    };
    return NetworkLayer.postJson(`${url}/${id}/enable`, request)
      .then(response => response)
      .catch(e => e);
  };

  fetchUsersList = (url, params) => {
    return NetworkLayer.getJson(url, {
      params
    })
      .then(response => response)
      .catch(e => e);
  };

  rejectSpDocument = (url, {rejectionReason, documentId}) => {
    const request = {
      params: {
        rejectionReason
      }
    };
    return NetworkLayer.postJson(`${url}/${documentId}/reject`, request)
      .then(response => response)
      .catch(e => e);
  };

  approveSpDocument = (url, {documentId}) => {

    return NetworkLayer.postJson(`${url}/${documentId}/approve`)
      .then(response => response)
      .catch(e => e);
  };

  fetchSpCreationDocuments = (url, params) => {
    return NetworkLayer.getJson(url, {
      params
    })
      .then(response => response)
      .catch(e => e);
  };

  uploadLogo = (url, file) => {
    const request = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: {
        file
      }
    };
    return NetworkLayer.postFormData(url, request)
      .then(response => response)
      .catch(e => e);
  };

  updateLogoUrl = (url, data) => {
    return NetworkLayer.putJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  signNDA = (url, data) => {
    const request = {
      data
    };
    return NetworkLayer.postJson(url, request)
      .then(response => response)
      .catch(e => e);
  };

  isSignNDA = (url, params) => {
    return NetworkLayer.getJson(url, {
      params
    })
      .then(response => response)
      .catch(e => e);
  };

  paymentPostRequest = (url, data) => {
    return NetworkLayer.postJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  getPaymentsList = (url, params) => {
    return NetworkLayer.getJson(url, {
      params
    })
      .then(response => response)
      .catch(e => e);
  };

  getPrivateBankersList = (url, params) => {
    return NetworkLayer.getJson(url, {
      params
    })
      .then(response => response)
      .catch(e => e);
  };

  saveUserScreening = ({id}) => {
    return NetworkLayer.postJson(`${utils.config.apiUserUrl}/users/${id}/kyc/check`)
      .then(response => response)
      .catch(e => e);
  };

  deletePrivateBanker = (url, { email }) => {
    return NetworkLayer.removeJson(`${url}/${email}`)
      .then(response => response)
      .catch(e => e);
  };

  getWalletBalance = (url, params) => {
    return NetworkLayer.getJson(url, {
      params
    })
      .then(response => response)
      .catch(e => e);
  };

  createPaymentBankAccount = (url, { data }) => {
    return NetworkLayer.postJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  investToProject = (url, { data, id }) => {
    return NetworkLayer.postJson(`${url}/${id}/invest`, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  investToProjectPrivateBankers = (url, { data, id }) => {
    return NetworkLayer.postJson(`${url}/${id}/private-banker/invest`, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  payForSubscription = (url, { data }) => {
    return NetworkLayer.postJson(`${url}/pay`, { data })
      .then(response => response)
      .catch(e => e);
  };

  getLastActiveSubscription = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  getSubscriptionHistory = (url, params) => {
    return NetworkLayer.getJson(url, {
      params
    })
      .then(response => response)
      .catch(e => e);
  };

  getPaymentForPublishing = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  validateInvestmentCode = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  investWithCode = (url, { data }) => {
    return NetworkLayer.postJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  SPKYCcheck = (url, data) => {
    return NetworkLayer.putJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  getUsersStatistic = url => {
    return this.fetchList(url);
  };

  addMoneyFromCardToWallet = (url, { data }) => {
    return NetworkLayer.postJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  addMoneyFromBankAccountToWallet = (url, { data }) => {
    return NetworkLayer.postJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  createUserCV = (url, { data }) => {
    return NetworkLayer.postJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  updateUserCV = (url, { data, id }) => {
    return NetworkLayer.putJson(`${url}/${id}`, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  getUserCV = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  setStepperStatus = (url, { data }) => {
    return NetworkLayer.putJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  moneyRepay = (url, { data }) => {
    return NetworkLayer.postJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  KYCUserStatus = (url, params) => {
    return NetworkLayer.getJson(url, {
      params
    })
      .then(response => response)
      .catch(e => e);
  };

  KYCDocumentsStatus = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  KYCDocumentsSave = (url, files) => {
    const request = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: {
        files
      }
    };
    return NetworkLayer.postFormData(url, request)
      .then(response => response)
      .catch(e => e);
  };

  uploadCVFile = (url, file) => {
    const request = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: {
        file
      }
    };
    return NetworkLayer.postFormData(url, request)
      .then(response => response)
      .catch(e => e);
  };

  createCVFile = (url, documents) => {
    return NetworkLayer.postJson(url, {
      data: { documents }
    })
      .then(response => response)
      .catch(e => e);
  };

  setUserKYCStatus = ({ id, reason, status }) => {
    const request = {
      data: {
        reason
      }
    };
    return NetworkLayer.postJson(`${utils.config.apiUserUrl}/users/${id}/kyc/${status}`, request)
      .then(response => response)
      .catch(e => e);
  };

  getUserPlatformCommission = url => {
    return NetworkLayer.getJson(url)
      .then(response => response)
      .catch(e => e);
  };

  putUserPlatformCommission = (url, data) => {
    return NetworkLayer.putJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  changeAutoRenewable = (url, data) => {
    return NetworkLayer.putJson(url, {
      data
    })
      .then(response => response)
      .catch(e => e);
  };

  contactUsRequest = (url, { data }) => {
    return NetworkLayer.postJson(
      `${url}${sessionStorage.access_token ? '/users/me' : ''}/contact-us`,
      {
        data
      }
    )
      .then(response => response)
      .catch(e => e);
  };

  getRegions = () => {
    return NetworkLayer.getJson(`${sharedUrlsConfig.publicUserUrl}/regions`)
      .then(response => response)
      .catch(e => e);
  };

  // getSpPlatformConfig = subDomain => {
  //   return NetworkLayer.getJson(
  //     `${sharedUrlsConfig.apiPlatformConfigUrl}/white-label-product-config?subDomain=${subDomain}`
  //   )
  //     .then(response => response)
  //     .catch(e => e);
  // };

  getSpPlatformConfig = subDomain => {
    return NetworkLayer.getJson(
      `${sharedUrlsConfig.apiPlatformConfigUrl}/white-label-product-config?subDomain=${subDomain}`
    )
    .then(response => {
      console.log('SP Platform Config Data:', response); // ✅ Logs the fetched data
      return response;
    })
    .catch(e => {
      console.error('Error fetching SP Platform Config:', e); // Logs any errors
      return e;
    });
};

  getSpPlatformPublicConfig = subDomain => {
    return NetworkLayer.getJson(
      `${sharedUrlsConfig.apiPlatformPublicConfigUrl}/white-label-product-config?subDomain=${subDomain}`
    )
    .then(response => {
      console.log('SP Platform Config Data:', response); // ✅ Logs the fetched data
      return response;
    })
    .catch(e => {
      console.error('Error fetching SP Platform Config:', e); // Logs any errors
      return e;
    });
};

}

export default User;
