import React from 'react';
import PropTypes from 'prop-types';

const defaultStyles = {};

export default ({ style }) => {
  const customStyles = Object.assign({}, defaultStyles, style);
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28.1" rx="4" fill="#8A5B00"/>
    <path d="M13.9992 8V16M14.0492 20V20.1H13.9492V20H14.0492Z" stroke="#F0F0F0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
};