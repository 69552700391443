import React from 'react';
import { Route, Router, Switch } from 'react-router';
import asyncComponent from 'shared/utils/asyncComponent';
import PrivateRoute from '../hoc/privateRoute';
import * as pathname from 'shared/routes/urlLocations';
import { history } from 'shared/routes/urlLocations';
import UnauthorizedLayout from '../layouts/UnauthorizedLayout';
import UnauthorizedLayoutNew from '../layouts/UnauthorizedLayoutNew'
import UnauthorizedStubLayout from '../layouts/UnauthorizedStubLayout';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';

const AsyncLoginPage = asyncComponent(() => import('../containers/LoginPage'));
const AsyncHomePage = asyncComponent(() => import('../containers/HomePage'));
const AsyncNotFoundPage = asyncComponent(() => import('../containers/NotFoundPage'));
const AsyncRegistrationPage = asyncComponent(() => import('../containers/RegistrationPage'));
const AsyncRegistrationIssuerPage= asyncComponent(() => import('../containers/RegistrationIssuerPage'));
const AsyncRegistrationInvestorPage= asyncComponent(() => import('../containers/RegistrationInvestorPage'));
const AsyncRegistrationSPPage= asyncComponent(() => import('../containers/RegistrationSPPage'));
const AsyncRegistrationAdvisorPage= asyncComponent(() => import('../containers/RegistrationAdvisorPage'));
const AsyncConfirmEmailPage = asyncComponent(() => import('../containers/ConfirmEmailPage'));
const AsyncConfirmInvestorPage = asyncComponent(() => import('../containers/ConfirmInvestorPage'));
const AsyncRestorePasswordPage = asyncComponent(() => import('../containers/RestorePasswordPage'));
const AsyncProjectCreationPage = asyncComponent(() => import('../containers/ProjectCreationPage'));
const AsyncStubPage = asyncComponent(() => import('../containers/StubPage'));
const AsyncStubChooseRolePage = asyncComponent(() => import('../containers/StubChooseRolePage'));
const AsyncAllProjectsListPage = asyncComponent(() => import('../containers/AllProjectsListPage'));
const AsyncFavoriteProjectsPage = asyncComponent(() => import('../containers/FavoriteProjectsPage'));
const AsyncProjectDetailsPage = asyncComponent(() => import('../containers/ProjectDetailsPage'));
const AsyncUserProjectsPage = asyncComponent(() => import('../containers/UserProjectsPage'));
const AsyncProjectInvestmentsPage = asyncComponent(() => import('../containers/ProjectInvestmentsPage'));
const AsyncProjectDataRoomPage = asyncComponent(() => import('../containers/ProjectDataRoomPage'));
const AsyncProfilePage = asyncComponent(() => import('../containers/ProfilePage'));
const NotificationPage = asyncComponent(() => import('../containers/NotificationsPage'));
const SPSettingsPage = asyncComponent(() => import('../containers/SPSettingsPage'));
const AsyncSPAdminsListPage = asyncComponent(() => import('../containers/StrategicPartners/AdminsListPage'));
const AsyncSPAdminCreatePage = asyncComponent(() => import('../containers/StrategicPartners/AdminCreatePage'));
const AsyncSPWorkersListPage = asyncComponent(() => import('../containers/StrategicPartners/WorkersListPage'));
const AsyncSPWorkerCreatePage = asyncComponent(() => import('../containers/StrategicPartners/WorkerCreatePage'));
const AsyncSPInvestorsListPage = asyncComponent(() => import('../containers/StrategicPartners/InvestorsListPage'));
const AsyncSPInvestorCreatePage = asyncComponent(() => import('../containers/StrategicPartners/InvestorCreatePage'));
const AsyncSPIssuersListPage = asyncComponent(() => import('../containers/StrategicPartners/IssuersListPage'));
const AsyncSPIssuerCreatePage = asyncComponent(() => import('../containers/StrategicPartners/IssuerCreatePage'));
const AsyncSPCreationHistory = asyncComponent(() => import('../containers/StrategicPartners/CreationHistory'));
const AsyncProjectRejectionsHistory = asyncComponent(() => import('../containers/ProjectRejectionsHistoryPage'));
const AsyncSPProjectsPage = asyncComponent(() => import('../containers/SPProjectsPage'));
const AsyncSPIssuerProjects = asyncComponent(() => import('../containers/StrategicPartners/IssuerProjects'));
const AsyncUserRoleDescription = asyncComponent(() => import('../containers/UserRoleDescriptionPage'));
const AsyncAboutPage = asyncComponent(() => import('../containers/AboutPage'));
const AsyncPartnersPage = asyncComponent(() => import('../containers/PartnersPage'));
const AsyncErrorLostConnection = asyncComponent(() => import('shared/components/ErrorLostConnection'));
const KYCUserStatusPage = asyncComponent(() => import('../containers/KYCUserStatusPage'));
const AsyncPrivacyPolicyPage = asyncComponent(() => import('shared/containers/PrivacyPolicyPage'));
const AsyncCookiesPage = asyncComponent(() => import('shared/containers/CookiesPage'));
const AsyncTermsOfUsePage = asyncComponent(() => import('shared/containers/TermsOfUsePage'));

function RouteWithLayout({
                           isPrivate, layout, component, path, ...rest
                         }) {
  return !isPrivate ? (
    <Route
      {...rest}
      path={path}
      render={props => React.createElement(layout, props, React.createElement(component, props))}
    />
  ) : (
    <PrivateRoute
      {...rest}
      path={path}
      component={props => React.createElement(layout, props, React.createElement(component, props))}
    />
  );
}

export default () => (
  <Router history={history}>
    <Switch>
      {/* Public Routes (No MainLayout) */}
      <RouteWithLayout exact path={pathname.stubPage} layout={UnauthorizedStubLayout} component={AsyncStubPage} />
      <RouteWithLayout exact path={pathname.issuerUserRole} layout={UnauthorizedStubLayout} component={AsyncUserRoleDescription} />
      <RouteWithLayout exact path={pathname.investorUserRole} layout={UnauthorizedStubLayout} component={AsyncUserRoleDescription} />
      <RouteWithLayout exact path={pathname.advisorUserRole} layout={UnauthorizedStubLayout} component={AsyncUserRoleDescription} />
      <RouteWithLayout exact path={pathname.strategicPartnerUserRole} layout={UnauthorizedStubLayout} component={AsyncUserRoleDescription} />
      <RouteWithLayout exact path={pathname.partners} layout={UnauthorizedStubLayout} component={AsyncPartnersPage} />
      <RouteWithLayout exact path={pathname.about} layout={UnauthorizedStubLayout} component={AsyncAboutPage} />
      <RouteWithLayout exact path={pathname.stubChooseRolePage} layout={UnauthorizedLayout} component={AsyncStubChooseRolePage} />
      <RouteWithLayout exact path={pathname.callback} layout={UnauthorizedLayoutNew} component={AsyncLoginPage} />
      <RouteWithLayout exact path={pathname.login} layout={UnauthorizedLayoutNew} component={AsyncLoginPage} />
      <RouteWithLayout exact path={pathname.registration} layout={UnauthorizedLayout} component={AsyncRegistrationPage} />

      <RouteWithLayout exact path={pathname.registrationIssuer} layout={UnauthorizedLayoutNew} component={AsyncRegistrationIssuerPage} />
      <RouteWithLayout exact path={pathname.registrationInvestor} layout={UnauthorizedLayoutNew} component={AsyncRegistrationInvestorPage} />
      <RouteWithLayout exact path={pathname.registrationAdvisor} layout={UnauthorizedLayoutNew} component={AsyncRegistrationAdvisorPage} />
      <RouteWithLayout exact path={pathname.registrationSP} layout={UnauthorizedLayoutNew} component={AsyncRegistrationSPPage} />
      <RouteWithLayout exact path={pathname.restorePasswordToken} layout={UnauthorizedLayoutNew} component={AsyncRestorePasswordPage} />
      <RouteWithLayout exact path={pathname.restorePassword} layout={UnauthorizedLayoutNew} component={AsyncRestorePasswordPage} />
      <RouteWithLayout exact path={pathname.confirmEmailToken} layout={UnauthorizedLayoutNew} component={AsyncConfirmEmailPage} />
      <RouteWithLayout exact path={pathname.confirmInvestorToken} layout={UnauthorizedLayout} component={AsyncConfirmInvestorPage} />

      <RouteWithLayout exact path={pathname.privacy} layout={UnauthorizedStubLayout} component={AsyncPrivacyPolicyPage} />
      <RouteWithLayout exact path={pathname.cookie} layout={UnauthorizedStubLayout} component={AsyncCookiesPage} />
      <RouteWithLayout exact path={pathname.termsOfUse} layout={UnauthorizedStubLayout} component={AsyncTermsOfUsePage} />

      <RouteWithLayout exact isPrivate path={pathname.lostConnection} layout={ErrorLayout} component={AsyncErrorLostConnection} />

      {/* Routes Wrapped in MainLayout */}
      <Route
        path={[
          pathname.homePage,
          pathname.projectsMy,
          pathname.projectsAll,
          pathname.projectsFavorite,
          `${pathname.projectCreate}/:tab?`,
          `${pathname.projectDetails}/:id`,
          `${pathname.projectRejectionsHistory}/:id`,
          `${pathname.projectDataRoomAccess}/:id`,
          `${pathname.projectInvestments}/:id`,
          `${pathname.projectEditToken}/:tab?`,
          pathname.SPAdmins,
          pathname.SPAdminCreate,
          pathname.SPWorkers,
          pathname.SPWorkerCreate,
          pathname.SPInvestorsList,
          pathname.SPInvestorCreate,
          pathname.SPIssuersList,
          `${pathname.SPCreationHistory}/:userType`,
          pathname.SPIssuerCreate,
          pathname.userProfile,
          pathname.notifications,
          pathname.spSettings,
          pathname.spProjects,
          pathname.KYCUserIdStatus,
          pathname.projectsSPIssuer,
        ]}
        render={() => (
          <MainLayout>
            <Switch>
              <PrivateRoute exact path={pathname.homePage} component={AsyncHomePage} />
              <PrivateRoute exact path={pathname.projectsMy} component={AsyncUserProjectsPage} />
              <PrivateRoute exact path={pathname.projectsAll} component={AsyncAllProjectsListPage} />
              <PrivateRoute exact path={pathname.projectsFavorite} component={AsyncFavoriteProjectsPage} />
              <PrivateRoute exact path={`${pathname.projectCreate}/:tab?`} component={AsyncProjectCreationPage} />
              <PrivateRoute exact path={`${pathname.projectDetails}/:id`} component={AsyncProjectDetailsPage} />
              <PrivateRoute exact path={`${pathname.projectRejectionsHistory}/:id`} component={AsyncProjectRejectionsHistory} />
              <PrivateRoute exact path={`${pathname.projectDataRoomAccess}/:id`} component={AsyncProjectDataRoomPage} />
              <PrivateRoute exact path={`${pathname.projectInvestments}/:id`} component={AsyncProjectInvestmentsPage} />
              <PrivateRoute exact path={`${pathname.projectEditToken}/:tab?`} component={AsyncProjectCreationPage} />
              <PrivateRoute exact path={pathname.SPAdmins} component={AsyncSPAdminsListPage} />
              <PrivateRoute exact path={pathname.SPAdminCreate} component={AsyncSPAdminCreatePage} />
              <PrivateRoute exact path={pathname.SPWorkers} component={AsyncSPWorkersListPage} />
              <PrivateRoute exact path={pathname.SPWorkerCreate} component={AsyncSPWorkerCreatePage} />
              <PrivateRoute exact path={pathname.SPInvestorsList} component={AsyncSPInvestorsListPage} />
              <PrivateRoute exact path={pathname.SPInvestorCreate} component={AsyncSPInvestorCreatePage} />
              <PrivateRoute exact path={pathname.SPIssuersList} component={AsyncSPIssuersListPage} />
              <PrivateRoute exact path={`${pathname.SPCreationHistory}/:userType`} component={AsyncSPCreationHistory} />
              <PrivateRoute exact path={pathname.SPIssuerCreate} component={AsyncSPIssuerCreatePage} />
              <PrivateRoute exact path={pathname.userProfile} component={AsyncProfilePage} />
              <PrivateRoute exact path={pathname.notifications} component={NotificationPage} />
              <PrivateRoute exact path={pathname.spSettings} component={SPSettingsPage} />
              <PrivateRoute exact path={pathname.spProjects} component={AsyncSPProjectsPage} />
              <PrivateRoute exact path={pathname.KYCUserIdStatus} component={KYCUserStatusPage} />
              <PrivateRoute exact path={pathname.projectsSPIssuer} component={AsyncSPIssuerProjects} />
            </Switch>
          </MainLayout>
        )}
      />

      {/* Catch-all 404 Route (Outside MainLayout) */}
      <Route component={AsyncNotFoundPage} />
    </Switch>
  </Router>
);
