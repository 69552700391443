export default {
  fullIndividualInformation: {
    honorificPrefix: null,
    givenName: null,
    familyName: null,
    officePhone: null,
    phone: null,
    birthDate: null,
    gender: null,
    nationality: null,
    building: null,
    streetAddress: null,
    region: null,
    city: null,
    company: null,
    targetInvestmentSize: null,
    transactionsPerYear: null,
    postalCode: null,
    country: null,
    phone2: null,
    passportExpiry: null,
    passportCountry: null,
    passportNumber: null,
    drivingLicenseNumber: null
  },
  fullCompanyInformation: {
    givenName: null,
    familyName: null,
    building: null,
    streetAddress: null,
    region: null,
    city: null,
    postalCode: null,
    country: null,
    briefDescription: null,
    foundingLocation: null,
    legalName: null,
    phone: null,
    officePhone: null,
    website: null,
    registrationNumber: null
  },
  issuerProjectCreation: {
    availability: 'ALL',
    title: null,
    bankAccount: null,
    picture: null,
    endDate: null,
    target: null,
    targetAmount: null,
    currency: null,
    maxAmount: null,
    shortDescription: null,
    targetedReturns: null,
    fullDescription: null,
    ndaForDataRoom: false,
    ndaForDataRoomWithApproval: false
  },
  issuerCreate: {
    briefDescription: null,
    building: null,
    givenName: null,
    familyName: null,
    city: null,
    country: null,
    email: null,
    foundingLocation: null,
    legalName: null,
    postalCode: null,
    region: null,
    streetAddress: null,
    officePhone: null,
    phone: null,
    website: null,
    registrationNumber: null
  },
  issuerProjectTags: {
    regions: [],
    industrySector: null,
    category: {
      name: null,
      attributes: {}
    }
  },
  spWorkerCreate: {
    email: null,
    givenName: null,
    familyName: null,
    userType: 'WORKER_STRATEGIC_PARTNER'
  },
  spAdminCreate: {
    email: null,
    givenName: null,
    familyName: null
  },
  spCompanyKYC: {
    building: null,
    streetAddress: null,
    region: null,
    city: null,
    postalCode: null,
    country: null,
    briefDescription: null,
    foundingLocation: null,
    legalName: null,
    phone: null,
    officePhone: null,
    website: null,
    registrationNumber: null
  },
  userCVForm: {
    contactDetails: null,
    skillsSummary: null,
    workExperience: null,
    education: null
  },
  spContactMain: {
    givenName: null,
    familyName: null,
    phone: null,
    officePhone: null,
    selectedRMs: null,
  },
  spContactCompany: {
    building: null,
    streetAddress: null,
    region: null,
    city: null,
    postalCode: null,
    country: null,
    briefDescription: null,
    foundingLocation: null,
    legalName: null,
    website: null,
    registrationNumber: null,
    email: null
  },
  spContactIndividual: {
    email: null,
    honorificPrefix: null,
    birthDate: null,
    gender: null,
    nationality: null,
    building: null,
    streetAddress: null,
    region: null,
    city: null,
    company: null,
    targetInvestmentSize: null,
    transactionsPerYear: null,
    postalCode: null,
    country: null,
    phone2: null,
    passportExpiry: null,
    passportCountry: null,
    passportNumber: null,
    drivingLicenseNumber: null
  }
};
