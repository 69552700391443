import hash from 'object-hash';
import notification from 'shared/utils/notification';
import { message as messageAnt } from 'antd';
import messages from '../locales/en/common.json';
import staticMessages from '../locales/en/static.json';
import 'shared/styles/components/registration-forms.css';

const fieldLabelMap = {
  target: 'Total Size',
  targetAmount: 'Target Amount',
  endDate: 'End date',
  shortDescription: 'Description',
  picture: 'Project photo',
  targetedReturns: 'Target Return',
  fullDescription: 'Full Description',
  regions: 'Region',
  industrySector: 'Industry sector',
  category: 'Product Category',
  maxAmount: 'Max Amount'
};

export default ({
  type,
  className = '',
  data = null,
  duration = 0,
  notificationType = 'notification',
  staticNotification = false
}) => {
  let description = '';
  let message = '';

  let config = {
    type,
    duration,
    description: '',
    className
    // className: 'ant-notification-success'
    // className: `ant-notification-${type}`
  };
  let codes = staticNotification ? staticMessages[type] : messages[type];
  const id = [];
  let typeOf = {
    notification: data => {
      switch (data.constructor.name) {
        case 'Array':
          const prefix = data.length > 1 ? '\u22C5' : '';
          data.forEach(item => {
            let value = item.data && item.data.value ? item.data.value : '';
            let field = item.data && item.data.field ? item.data.field : '';
            let fieldLabel = fieldLabelMap[field] || field;
            message = '';
            if (codes[item.code]) message = codes[item.code].replace('%field', fieldLabel).replace('%value', value);
            description += message
              ? `${prefix} ${message}${'\n'}`
              : item.description
                ? `${prefix} ${item.description}${'\n'}`
                : `${description} ${message} ${'\n'}`;
            id.push({ code: item.code, field, value });
          });
          break;
        case 'String':
          description = data;
          break;
        default:
          const value = data.value ? data.value : '';
          const field = data.field ? data.field : '';
          const fieldLabel = fieldLabelMap[field] || field;
          message = '';
          if (codes[data.code]) message = codes[data.code].replace('%field', fieldLabel).replace('%value', value);
          description += message
            ? `${message}${'\n'}`
            : data.description
              ? `${data.description}${'\n'}`
              : `${description} ${message} ${'\n'}`;
          id.push({ code: data.code, field, value });
      }
      if (description) {
        config.id = hash(id.sort((a, b) => a.code > b.code));
        config.description = description;
        notification[type](config);
      }
    },
    message: data => {
      switch (data.constructor.name) {
        default:
          description = data;
          break;
      }
      messageAnt[type](data);
    }
  };

  return typeOf[notificationType](data);
};
