import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import countries from './countriesReducer';
import regions from './regionsReducer';
import currencies from './currenciesReducer';
import notifications from './notificationReducer';
import prompt from './promptReducer';
import showSpinner from './spinnerReducer';
import user from './userDataReducer';
import confirmInvestor from './confirmInvestorReducer';
import investmentWithCodeDetails from './investmentWithCodeDetailsReducer';
import projects from './projectsReducer';
import spPlatform from './spPlatformConfigReducer';
import tags from './tagsReducer';
import modals from './modalsReducer';
import target from './targetReducer';
import NDA from './signNDAReducer';
import payments from './paymentReducer';
import search from './searchReduser';
import statistic from './statisticReducer';
import confirmPassword from './confirmPasswordReducer';
import sharedReducers from 'shared/reducers';

// Combines all reducers to a single reducer function
const rootReducer = combineReducers({
  user,
  showSpinner,
  countries,
  currencies,
  regions,
  confirmPassword,
  notifications,
  prompt,
  confirmInvestor,
  investmentWithCodeDetails,
  projects,
  tags,
  modals,
  target,
  NDA,
  payments,
  spPlatform,
  search,
  statistic,
  form: formReducer,
  router: routerReducer,
  ...sharedReducers
});

export default rootReducer;
