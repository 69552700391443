import { ActionTypes } from '../constants';

export const RegisterPaymentCardAction = values => ({
  type: ActionTypes.REGISTER_PAYMENT_CARD,
  values
});

export const getPaymentCardsListAction = () => ({
  type: ActionTypes.GET_PAYMENT_CARDS_LIST
});

export const createPaymentBankAccount = data => ({
  type: ActionTypes.CREATE_PAYMENT_BANK_ACCOUNT,
  requestConfig: {
    data
  }
});

export const getPaymentBankAccountsListAction = () => ({
  type: ActionTypes.GET_PAYMENT_BANK_ACCOUNTS_LIST
});

export const validateInvestmentCodeAction = (projectID, investmentCode, onAsyncValidionFailed) => ({
  type: ActionTypes.VALIDATE_INVESTMENT_CODE,
  payload: {
    projectID,
    investmentCode,
    onAsyncValidionFailed
  }
});

export const setInvestmentWithCodeDetailsAction = details => ({
  type: ActionTypes.SET_INVESTMENT_WITH_CODE_DETAILS,
  payload: {
    details
  }
});

export const clearInvestmentWithCodeDetailsAction = () => ({
  type: ActionTypes.CLEAR_INVESTMENT_WITH_CODE_DETAILS
});

export const investWithCodeAction = (projectID, investmentCode) => ({
  type: ActionTypes.INVEST_WITH_CODE,
  payload: {
    projectID,
    investmentCode
  }
});

export const investToProjectAction = (data, id) => ({
  type: ActionTypes.INVEST_TO_PROJECT,
  requestConfig: {
    data,
    id
  },
  onSuccessActions: [
    {
      type: ActionTypes.TOGGLE_INVEST_MODAL
    },
    {
      type: ActionTypes.INVESTOR_GET_PROJECT_DETAILS,
      id
    }
  ]
});

export const investToProjectPrivateBankersAction = (data, id) => {
  return {
  type: ActionTypes.INVEST_PROJECT_PRIVATE_BANKERS,
  requestConfig: {
    data,
    id
  },
  onSuccessActions: [
    {
      type: ActionTypes.TOGGLE_INVEST_PRIVATE_BANKERS_MODAL
    },
    {
      type: ActionTypes.INVESTOR_GET_PROJECT_DETAILS,
      id
    }
  ]
}};

export const getSubscriptionListAction = (subDomain = null) => ({
  type: ActionTypes.GET_SUBSCRIPTIONS_LIST,
  requestConfig: {
    subDomain
  }
});

export const getLastActiveSubscriptionAction = () => ({
  type: ActionTypes.GET_LAST_ACTIVE_SUBSCRIPTION
});

export const getSubscriptionHistoryAction = (
  { size = 20, current = 1 } = {},
  filters,
  { field = 'expiredAt', order = 'desc' } = {}
) => ({
  type: ActionTypes.GET_SUBSCRIPTION_HISTORY,
  requestConfig: {
    sort: `${field},${order}`,
    size: size,
    page: current - 1
  }
});

export const payForSubscriptionAction = type => ({
  type: ActionTypes.PAY_FOR_SUBSCRIPTION,
  requestConfig: {
    data: {
      type
    }
  }
});

export const changeAutoRenewableAction = data => ({
  type: ActionTypes.CHANGE_AUTORENEWABLE_SUBSCRIPTION,
  requestConfig: data
});

export const resetBankCredentialsAction = () => ({
  type: ActionTypes.REST_BANK_CREDENTIALS
});

export const getPaymentForPublishingAction = () => ({
  type: ActionTypes.GET_PAYMENT_FOR_PUBLISHING
});

export const getWalletBalance = () => ({
  type: ActionTypes.GET_WALLET_BALANCE
});

export const addMoneyFromCardToWalletAction = (data, id) => ({
  type: ActionTypes.ADD_MONEY_FROM_CARD_TO_WALLET,
  data: {
    walletId: id,
    paymentAmount: data.paymentAmount,
    id: data.paymentId,
    secureModeReturnUrl: `${window.location.origin}${window.location.pathname}#payment-methods`
  }
});

export const repayMoneyAction = data => ({
  type: ActionTypes.REPAY_MONEY,
  requestConfig: {
    data
  },
  onSuccessActions: [
    {
      type: ActionTypes.TOGGLE_REPAY_MODAL
    },
    {
      type: ActionTypes.GET_WALLET_BALANCE,
      id: data.debitedWalletId
    }
  ]
});

export const addMoneyFromBankAccountToWalletAction = (data, id) => ({
  type: ActionTypes.ADD_MONEY_FROM_BANK_ACCOUNT_TO_WALLET,
  requestConfig: {
    data: {
      currency: 'USD',
      amount: data.paymentAmount,
      creditedWalletId: id
    }
  }
});
