import { ActionTypes } from '../constants';
import {fetchListSPAdmins} from "./spAdminsActions";

export const fetchListSPWorkers = (
  page,
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_WORKERS_LIST_REQUEST,
  requestConfig: {
    projection: 'admin',
    sort: `${sort.field},${sort.order}`,
    size: 20,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const fetchListRMWorkers = (
  page,
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_RMS_LIST_REQUEST,
  requestConfig: {
    projection: 'admin',
    sort: `${sort.field},${sort.order}`,
    size: 20,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const setSelectedRMs = (selectedRMs) => ({
  type: ActionTypes.SP_RMS_SELECTED,
  payload: selectedRMs,
});

export const setUpdatedRMs = (updatedRMs) => {
  return {
    type: ActionTypes.SP_RMS_UPDATED,
    payload: updatedRMs,
  };
};

export const setSelectedInternalPoc = (selectedRMs) => ({
  type: ActionTypes.SP_INTERNAL_POC_SELECTED,
  payload: selectedRMs,
});

export const setSelectedExternalPoc = (selectedRMs) => ({
  type: ActionTypes.SP_EXTERNAL_POC_SELECTED,
  payload: selectedRMs,
});

export const handleDeselectPoc = (fieldName, selectedRMs) => {
  console.log("handleDeselectPoc called with:", {
    fieldName,
    selectedRMs,
  });

  return {
    type: ActionTypes.SP_HANDLE_DESELECT_POC,
    payload: {
      fieldName,
      selectedRMs,
    },
  };
};


export const setProjectPocs = (projectId, pocs) => {
  return {
    type: ActionTypes.SET_PROJECT_POCS_REQUEST,
    requestConfig: {
      projectId: projectId,
      externalPocs: pocs.externalPoc,
      internalPocs: pocs.internalPoc,
    },
    onSuccessActions: {
        type: ActionTypes.INVESTOR_GET_PROJECT_DETAILS,
        id: projectId,

    }
  };
};

export const updateRMs = (userId, updatedRMs) => ({
  type: ActionTypes.SP_RMS_UPDATE_REQUEST,
  requestConfig: {
    id: userId,
    updatedRMs: updatedRMs,
  },
});

export const spUserActivation = (id, enable, usersType) => ({
  type: ActionTypes.SP_USER_ACTIVATION_REQUEST,
  requestConfig: {
    id,
    enable
  },
  onSuccessActions: {
    type: ActionTypes[`SP_${usersType}_LIST_FETCH`],
    requestConfig: {
      sort: 'createdAt,desc',
      size: 20,
      page: 0
    }
  }
});


export const spWorkerCreate = values => ({
  type: ActionTypes.SP_WORKER_CREATE,
  requestConfig: {
    ...values,
    type: 'USER'
  }
});

export const spWorkerActivation = (id, enable, currentPage) => ({
  type: ActionTypes.SP_WORKER_ACTIVATION_REQUEST,
  requestConfig: {
    id,
    enable
  },
  onSuccessActions: {
    type: ActionTypes.SP_WORKERS_LIST_REQUEST,
    requestConfig: {
      projection: 'admin',
      sort: 'createdAt,desc',
      size: 20,
      page: currentPage || 0
    }
  }
});

export const spInvestorCreate = values => ({
  type: ActionTypes.SP_INVESTOR_REGISTRATION,
  requestConfig: {
    ...values,
    userType: 'INVESTOR_STRATEGIC_PARTNER'
  }
});

export const spInvestorsListFetch = (
  page,
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_INVESTORS_LIST_FETCH,
  requestConfig: {
    sort: `${sort.field},${sort.order}`,
    size: 20,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const spIssuerCreate = values => ({
  type: ActionTypes.SP_ISSUER_REGISTRATION,
  requestConfig: {
    ...values,
    userType: 'ISSUER_STRATEGIC_PARTNER'
  }
});

export const spIssuersListFetch = (
  page,
  filters,
  sort = { field: 'createdAt', order: 'desc' }
) => ({
  type: ActionTypes.SP_ISSUERS_LIST_FETCH,
  requestConfig: {
    sort: `${sort.field},${sort.order}`,
    size: 20,
    page: (page && page.current && page.current - 1) || 0
  }
});

export const spComplianceOfficerApproveContact = (id, currentPage, usersType) => ({
  type: ActionTypes.SP_COMPLIANCE_OFFICER_APPROVE_CONTACT,
  requestConfig: {
    id
  },
  onSuccessActions: {
    type: ActionTypes[`SP_${usersType}_LIST_FETCH`],
    requestConfig: {
      sort: 'createdAt,desc',
      size: 20,
      page: currentPage || 0
    }
  }
});

export const spComplianceOfficerRejectContact = (id, reason, currentPage, usersType) => ({
  type: ActionTypes.SP_COMPLIANCE_OFFICER_REJECT_CONTACT,
  requestConfig: {
    id,
    reason
  },
  onSuccessActions: {
    type: ActionTypes[`SP_${usersType}_LIST_FETCH`],
    requestConfig: {
      sort: 'createdAt,desc',
      size: 20,
      page: currentPage || 0
    }
  }
});
